<template>
  <div>
    <div class="tac" style="background: #FFFFFF;">
      <img src="../../assets/img/brand-logo.jpg" alt="">
    </div>
<!--    <div class="setps" style="min-width: 1330px">-->
<!--      <div class="setps_container">-->
<!--        <div class="setps_container_main">-->
<!--          <div class="setps_container_main_setp">-->
<!--            <h1>12年行业经验</h1>-->
<!--            <span></span>-->
<!--            <p>专业服务</p>-->
<!--          </div>-->
<!--          <div class="setps_container_main_setp">-->
<!--            <h1>1+N专属服务</h1>-->
<!--            <span></span>-->
<!--            <p>品质保障</p>-->
<!--          </div>-->
<!--          <div class="setps_container_main_setp">-->
<!--            <h1>200+产品种类</h1>-->
<!--            <span></span>-->
<!--            <p>产品多元</p>-->
<!--          </div>-->
<!--          <div class="setps_container_main_setp">-->
<!--            <h1>300+服务地区</h1>-->
<!--            <span></span>-->
<!--            <p>服务地区</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  name: 'service-news',
  components: {},
  data() {
    return {
      newsList: [],
      noticeList: [],
    }
  },
  mounted() {
    this.getNewestNews()
    this.getNewestNotice()
  },
  methods: {
    // 获取最新新闻
    getNewestNews() {
      this.$http({
        url: this.$http.adornUrl(this.$api.NEWS.LIST),
        method: 'get',
        params: this.$http.adornParams({
          'page': 1,
          'limit': 5,
          'newsStatus': 1
        })
      }).then(({
                 data
               }) => {
        if (data && data.code === 0) {
          this.newsList = data.page.list
        }
      })
    },
    // 获取最新通知
    getNewestNotice() {
      this.$http({
        url: this.$http.adornUrl(this.$api.NOTICE.LIST),
        method: 'get',
        params: this.$http.adornParams({
          'page': 1,
          'limit': 5,
          'noticeStatus': 1
        })
      }).then(({
                 data
               }) => {
        if (data && data.code === 0) {
          this.noticeList = data.page.list
        }
      })
    },
    // 跳转到新闻页
    toNewsPage(tab, obj) {
      this.$router.push({
        name: 'newsNoticePage',
        params: {
          tab,
          obj
        }
      })
    }
  }
}
</script>


<style scoped>
.news {
  width: 100%;
}

.mrc-content {
  line-height: 38px;
}

.mrc a {
  cursor: pointer;
  text-decoration: none;
  color: #333333;
  font-size: 16px;
  margin-left: 5px;
}

.mrc a:hover {
  color: #327ac7;
  text-decoration: underline;
}

.toe1 {
  width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.setps {
  width: 100%;
  height: 200px;
  /*background: url(../../assets/img/steps.png) no-repeat;*/
  background-color: #4647BB;
  background-size: cover;
  background-position: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.setps .setps_container {
  width: 1200px;
}

.setps_container .setps_container_main {
  width: 100%;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.setps_container .setps_container_main .setps_container_main_setp {
  padding: 55px 0;
}

.setps_container .setps_container_main .setps_container_main_setp h1 {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 40px;
}

.setps_container .setps_container_main .setps_container_main_setp span {
  display: block;
  margin: 10px 0 13px;
  width: 30px;
  height: 3px;
  background: #FFFFFF;
}

.setps_container .setps_container_main .setps_container_main_setp p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
</style>

<template>
  <div class="header-bg">
    <page-header2/>
    <div class="banner-wrapper">
      <div class="content-desc">
        <div class="fwb">平台企业</div>
        <div class="fwb">综合质量服务平台</div>
        <div class="f24">致力于解决平台企业发展难题</div>
        <div>
          <el-button @click="goSelf"
                     style="background-color: #FFFFFF;width: 160px;height:50px;font-size: 20px;font-weight: bold;color: #4E58C8">
            立 即 使 用
          </el-button>
          <el-button @click="goSupport"
                     style="margin-left: 20px;background-color: #FFFFFF;width: 160px;height:50px;font-size: 20px;font-weight: bold;background: transparent;color: #FFFFFF">
            加 入 我 们
          </el-button>
        </div>
      </div>
    </div>
    <!--数量统计模块begin-->
    <div class="part1 animate__animated animate__fadeInUp">
      <div class="wrap uf uf-pa">
        <div class="li uf uf-ac">
          <div>
            <div class="h fwb tac f26">
              <count-to :startVal="0" :endVal="totalData.enterpriseTotal"/>
            </div>
            <div class="dec tac">
              平台企业数
            </div>
          </div>
        </div>
        <div class="li uf uf-ac">
          <div>
            <div class="h fwb tac f26">
              <count-to :startVal="0" :endVal="totalData.supportTotal"/>
            </div>
            <div class="dec">
              服务商数量
            </div>
          </div>
        </div>
        <div class="li uf uf-ac">
          <div>
            <div class="h fwb tac f26">
              <count-to :startVal="0" :endVal="totalData.dealTotal"/>
            </div>
            <div class="dec tac">
              订单成交量
            </div>
          </div>
        </div>
        <div class="li uf uf-ac">
          <div>
            <div class="h fwb tac f26">
              <count-to :startVal="0" :endVal="totalData.userCount"/>
            </div>
            <div class="dec tac">
              注册用户数量
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--数量统计模块 end-->
    <!--三大服务 begin-->
    <div class="part2">
      <div @click="$router.push({name: 'index'})" class="item">
        <div class="img-header">
          <img src="../assets/img/serve/zizhushi1.png" width="80" height="80">
        </div>
        <div class="desc">
          <p class="fwb">自助式服务</p>
          <div class="f16 lh1 txt">
            <div class="tac">
              <div>快捷检索服务 私人订制需求</div>
              <div>急速接单 全流程跟踪</div>
            </div>
          </div>
        </div>
      </div>
      <div @click="$router.push({name: 'recommend'})" class="item">
        <div class="img-header">
          <img src="../assets/img/serve/tuijianshi1.png" width="80" height="80">
        </div>
        <div class="desc">
          <p class="fwb">推荐式服务</p>
          <div class="f16 lh1 txt tac">
            <div class="tac wi100">汇聚企业特征 采集企业服务</div>
            <div class="tac">分析企业需求 自动化推荐</div>
          </div>
        </div>
      </div>
      <div @click="$router.push({name: 'quality-doctor'})" class="item">
        <div class="img-header">
          <img src="../assets/img/serve/yizhanshi1.png" width="80" height="80">
        </div>
        <div class="desc">
          <p class="fwb">一站式服务</p>
          <div class="f16 lh1 txt">
            <div>7*24h专属服务 企业级集成服务</div>
            <div>分析市场动态 专家级定制</div>
          </div>
        </div>
      </div>
    </div>
    <!--三大服务 end-->
    <div class="part3">
      <div class="part-header"><i class="el-icon-data-line"/> 行业贡献</div>
      <div class="content-row">
        <div class="row-img-wrapper">
          <div class="img-container">
            <el-carousel height="300px" :interval="3000">
              <el-carousel-item v-for="item in imgList1" :key="item.src">
                <el-image  :src="item.src" :fit="item.fit" class="img-wrapper"></el-image>
              </el-carousel-item>
            </el-carousel>
<!--            <img width="80%" height="80%" src="../assets/img/gongxian1/1.jpg"/>-->
          </div>
        </div>
        <div class="row-desc-wrapper">
          <div class="desc-container">
            <div class="desc-title mt-4 mb-4 tac">线上线下协同服务，助力企业渡过疫情难关</div>
            <div class="desc-content mt-2">
              疫情期间，平台企业送检不便，借助综合质量服务平台，
              开辟了“平台在线下单-联动物流运样-检测专人接样-实验室协同检测-质量医生视频协助”的线上线下协同服务方式。
              质量医生主动提供远程咨询，协助企业改进产品，大幅缩短产品检测周期。疫情期间已坚持为1000余家企业提供低收费检测服务。
              <el-divider/>
            </div>
          </div>
        </div>
      </div>
      <div class="content-row2">
        <div class="row-desc-wrapper">
          <div class="desc-container">
            <div class="desc-title mt-4 mb-4 tac">深入企业生产一线，探索平台企业多样化需求</div>
            <div class="desc-content mt-2">
              深入企业一线，摸清企业质量发展需求，
              通过一站式服务为企业产品质量提升、生产标准制定、品牌价值发展等量身定制一揽子规划，
              针对企业在产品质量问题上的安全隐患和相应的质量提升手段等内容提出了针对性的改进意见，为企业“问诊把脉”、答疑解惑。
            </div>
            <el-divider/>
          </div>
        </div>
        <div class="row-img-wrapper">
          <div class="img-container">
            <el-carousel height="300px" :interval="4000" :loop="false">
              <el-carousel-item v-for="item in imgList2" :key="item.src">
                <el-image :src="item.src" :fit="item.fit" class="img-wrapper"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="content-row">
        <div class="row-img-wrapper">
          <div class="img-container">
            <img width="80%" height="80%" src="../assets/img/gongxian1/3.png"/>
          </div>
        </div>
        <div class="row-desc-wrapper">
          <div class="desc-container">
            <div class="desc-title mt-4 mb-4 tac">多维度全方位赋能，提高平台企业核心竞争力</div>
            <div class="desc-content mt-2">
              从技术赋能、活动赋能、服务赋能、生态赋能、团队赋能等方面为平台企业提供全方位的帮扶，
              2021年累计服务高精尖领域平台企业将近400家，其中服务上市企业84家，服务国家级专精特新小巨人企业18家，
              服务国家高新技术企业253家，服务北京市专精特新小巨人企业32家，服务北京市专精特新中小企业21家。
              <el-divider/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part4">
    </div>
    <NewsNotice/>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import {clearLoginInfo} from '../utils'
import PageHeader2 from '../components/page-header2'
import NewsNotice from './index/news-notice'
export default {
  name: 'home-default',
  components: {
    PageHeader2,
    CountTo,
    NewsNotice
  },
  props: {},
  data() {
    return {
      defAvatarUrl: require('../../public/img/male.jpg'),
      userAvatarUrl: require('../../public/img/wuyanzu.jpg'),
      showHeaderBadge: true,
      totalData: {}
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.user.name
      },
    },
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      },
      set(val) {
        this.$store.commit('user/updateLoginStatus', val)
      }
    },
    unRead: {
      get() {
        return this.$store.state.user.unRead
      },
      set(val) {
        this.$store.commit('user/updateUnRead', val)
      }
    },
    imgList1 () {
      return [
        {
          fit: 'scale-down',
          src: require('../assets/img/gongxian1/1.png')
        },
        {
          fit: 'scale-down',
          src: require('../assets/img/gongxian1/1_2.png')
        }
      ]
    },
    imgList2 () {
      return [
        {
          fit: 'contain',
          src: require('../assets/img/gongxian1/2.png')
        },
        {
          fit: 'contain',
          src: require('../assets/img/gongxian1/2_2.png')
        }
      ]
    }
  },
  watch: {},
  created() {
    this.getTotalCount()
  },

  methods: {
    getTotalCount () {
      this.$http({
        url: this.$http.adornUrl(this.$api.STATS.TOTAL_DATA),
        method: 'GET'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.totalData = data.dataTotal.statsTotal || {}
        }
      })
    },
    goSelf() {
      this.$router.push({name: 'index'})
    },
    goSupport() {
      this.$router.push({name: 'support'})
    },
    logout() {
      this.$confirm('确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(this.$api.SYS.LOGOUT),
          method: 'post'
        }).then(({data}) => {
          clearLoginInfo()
          this.$router.push({name: 'index'})
        })
      }).catch(() => {
      })
    },
  }
}
</script>
<style scoped>
.header-bg {
  /*background-image: url("../assets/img/serve/hero.jpg");*/
}

.banner-wrapper {
  min-width: 620px;
  min-height: 600px;
  padding: 200px 0 160px 0;
  background-image: url("../assets/img/serve/hero2.png");
  background-position: 46% 30%;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  letter-spacing: 10px;
}

.banner-wrapper .content-desc {
  line-height: 3.8rem;
  font-size: 2.6rem;
  color: white;
  padding-top: 0px;
  padding-left: 20%;
  text-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.part1 {
  position: absolute;
  width: 60%;
  left: 18%;
  top: 510px;
  color: #FFFFFF;
}

.part1 .wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /*width: 1300px;*/
  /*margin: 0 auto;*/
}

.part2 {
  padding: 3% 16% 0 16%;
  display: flex;
  justify-content: space-around;
  background-color: #FFFFFF;
}

.part2 .item {
  cursor: pointer;
  min-height: 200px;
  width: 250px;
  padding: 10px 10px;
  /*background-color: #4E58C8;*/
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.enhence-title {
  font-size: 4rem;
  font-weight: 800;
  color: red;
}

.part2 .item .img-header {
  margin: 0 auto;
  padding: 10px;
  background-color: #4647BB;
  opacity: .8;
  border-radius: 20px;
}

.part2 .item .desc {
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.2rem;
}

.part2 .item .desc .txt {
  height: 150px;
  overflow: hidden;
  color: #606266;
}

.part3 {
  min-height: 500px;
  background-color: #FFFFFF;
}

.part-header {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  /*margin-bottom: 2rem;*/
}

.content-title {
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  color: #67C23A;
}

.content-row {
  height: 450px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-content: center;
  /*background-image: url("../../assets/img/serve/group6.png");*/
}

.sub-desc {
  margin-top: 1rem;
  font-size: 1rem;
  color: #F4F6FC;
  font-weight: normal;
  line-height: 1.8rem;
}

.row-img-wrapper {
  /*background-color: #409EFF;*/
  width: 40%;
  height: 80%;
}

.img-container {
  /*height: 500px;*/
  /*margin-left: 10%;*/
  margin: 10% 10%;
  /*display: flex;*/
  /*justify-content: center;*/
  /*justify-items: center;*/
}

.desc-container {
  margin: 10% auto;
  /*text-align: center;*/
}

.desc-title {
  width: 80%;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.desc-content {
  width: 80%;
  line-height: 1.8rem;
  text-indent: 2rem;
}

.row-desc-wrapper {
  /*background-color: red;*/
  width: 40%;
  height: 100%;
}

.content-row2 {
  height: 450px;
  background-color: #F2F6FC;
  display: flex;
  justify-content: center;
  align-content: center;
}

.part4 {
  /*min-height: 800px;*/
  /*background-color: red;*/
}
.img-wrapper {
  width: 98%;
  height: 98%;
}
</style>
